.box-compose {
    @extend .d-flex;
    @extend .flex-column;
    @extend .w-100;
    height: 140px;
    @extend .mt-auto;
    @extend .align-self-end;
    position: relative;
    background-color: #ededed;
    @extend .pt-2;
    > div {
        // border: 1px solid red;
        @extend .p-2;
    }
    .details {
        @extend .d-flex;
    }
    .icon-clip {
        font-size: 2rem;
        margin-top: auto;
        cursor: pointer;
    }

    .loading {
        @extend .text-center;
        @extend .text-primary;
        @extend .justify-content-center;
    }
    .icon-cross {
        @extend .align-self-end;
        margin-right: auto;
        margin-bottom: auto;
        color: #dc3545;
        cursor: pointer;
    }
    .image-preview {
        height: 100px;
        max-width: 100%;
    }
}

.com-parent-message {
    @extend .d-flex;
    @extend .justify-content-between;
    .info {
        @extend .d-flex;
        @extend .w-100;
        @extend .mb-1;
        // @extend .justify-content-ter;
    }

    .close-box {
        color: #cf000f;
    }
    .txt {
        @extend .p-2;
        min-height: 50px;
    }
    img {
        height: 50px;
    }
}

.compose-setting {
    @extend .position-relative;
    @extend .p-2;
    @extend .d-flex;
    @extend .flex-column;
}

.compose-file {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
}

.com-box-file {
    @extend .d-flex;
    @extend .flex-column;
    @extend .p-0;
    > div {
        @extend .d-flex;
    }
    .box {
        @extend .py-2;
        @extend .px-1;
        border: 1px solid #dbdbdb;
        @extend .mb-1;
    }
}
.compose-textarea {
    @extend .form-control;
    overflow: hidden;
    background-color: #ffffff;
    @extend .ml-2;
    resize: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 9px 12px 11px;
    outline: none;
    box-shadow: none;
}

.compose-confirm {
    @extend .align-self-end;
    @extend .btn;
    @extend .btn-success;
    @extend .mb-2;
    @extend .px-1;
    background-color: #38c172;
    border-color: #38c172;
}
