.wrapper-chat {
    @extend .d-flex;
    @extend .flex-column;
    height: 100%;
    position: relative;
    background-color: #eff3f6;
}
.c-conversation {
    @extend .d-flex;
    @extend .flex-column;
    header {
        // @extend .bg-danger;
        @extend .text-white;
        @extend .text-center;
        @extend .py-2;
    }
}
.messages-wrapper {
    @extend .p-1;
    @extend .m-0;
    @extend .shadow-sm;
}

.message-lists {
    @extend .px-3;
    @extend .pb-2;

    li {
        border: 1px solid #f5f5f5;
        @extend .py-1;
        > div {
            @extend .d-flex;
        }
    }
    .date-time {
        @extend .d-inline-block;
        direction: ltr;
    }
    .user-info {
        width: 100px;
        height: 100px;
        @extend .text-center;

        img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }

    .content {
        // word-break: break-all;
        @extend .shadow-sm;
        background-color: #ffffff;
        @extend .w-100;
        border: 1px solid #ededed;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        @extend .p-2;
    }
    .admin {
        .content {
            margin-right: 1rem !important;
        }
    }
    .user {
        > div {
            @extend .flex-row-reverse;
        }
        .content {
            margin-left: 1rem !important;
        }
    }

    .msg-info {
        @extend .align-self-end;
        margin-top: auto;
        @extend .w-100;
    }

    .seen {
        color: #149a14;
        @extend .ml-2;
        .icon-tick-3:before {
            color: #149a14 !important;
            font-weight: bolder;
        }
    }
    .deliver {
        .icon-tick-3:before {
            color: #000000 !important;
        }
    }
    .send {
        @extend .ml-2;
        .icon-tick-3:before {
            color: #000000 !important;
        }
    }
}

.event-setting {
    @extend .position-relative;
    height: 20px;
    .icon-down-arrow {
        @extend .position-absolute;
        left: 0;
        cursor: pointer;
        font-size: 1.2rem;
        @extend .font-weight-bold;
    }
}

.setting-menu-list {
    @extend .position-absolute;
    @extend .list-group;
    top: 18px;
    left: -7px;
    width: 120px;
    border: 1px solid #dbdbdb;
    background-color: #fff;
}

.msg-content {
    @extend .text-justify;
    word-wrap: break-word;
    overflow-wrap: break-word;
    
    .parent-info {
        @extend .d-flex;
        @extend .justify-content-between;
        @extend .p-2;
        border: 1px solid #dbdbdb;
        background-color: #f3f3f3;
        @extend .mb-2;
        img {
            height: 60px;
        }
    }
}

.chat-image {
    max-width: 100%;
    cursor: pointer;
}

.swal-wide {
    width: 95% !important;
}
