//@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-v4-rtl/scss/bootstrap.scss';
@import './Assets/css/style.css';
@import './Assets/style.css';


@import './Assets/scss/body.scss';
@import './Assets/scss/loader.scss';
@import './Assets/scss/notFound.scss';
@import './Assets/scss/auth.scss';
@import './Assets/scss/navigation';
@import './Assets/scss/aside.scss';
@import './Assets/scss/chat.scss';



.layout{    
    height: 100vh;
    @extend .border;  
    @extend .d-flex;  
}

.main-container{
    @extend .d-flex;
    @extend .w-100;
    height: 100%;   
}


.splash-screen{
    border: 1px solid #dbdbdb;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .w-100;
    >div{
        @extend .align-self-center;
    }
}
