.sign{
    height: 100vh;
    @extend .border;  
    @extend .d-flex; 
    @extend .justify-content-center;
    @extend .align-items-center;
}
.c-login{
    border: 1px solid #dee2e6;
    width: 500px;
    @extend .p-3;
    @extend .shadow;
    h2{
        @extend .font-weight-bold;
        @extend .text-center;
        color: #0079c4;
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
}