.sidebar{
    @extend .d-flex;
    @extend .flex-column;
    width: 365px;
    height: 100%;
    // border: 1px solid red;
}
// start aside-header
.aside-header{
    border-bottom: 1px solid #cccccc;
    @extend .d-flex;
    @extend .mb-0;
    li{        
        @extend .flex-fill;
        @extend .py-2;
        a{
            @extend .px-2;           
            color: #333333;
        }
        a.active{
            color: #0079c4;
        }
    }
}
// end aside-header

// start users-container
.scrollarea{
    border: 1px solid #d3d3d3;
    @extend .mb-0;
    flex: 1;
}
.users-container{
    border: 1px solid #d3d3d3;
    @extend .mb-0;
    // flex: 1;

    li{
    }
}
.user-item{    
    @extend .d-flex;
    @extend .justify-content-between;        
    border: 1px solid #dbdbdb;
    min-height: 40px;
    cursor: pointer;
    @extend .p-1;
    &:hover {
        border: 1px solid #cccccc;
        background-color: #f3f3f3;
    }
    .user-data{      
        @extend .w-100;
    }
    .user-info{
        @extend .d-flex;
        @extend .justify-content-between;        
    }
    .fullname{
        @extend .ml-auto ;
        @extend .pr-1;
        @extend .text-primary;
        @extend .font-weight-bold;
    }
    .created_at{
        direction: ltr;
        font-size: 0.6rem;
    }
    p{
        @extend .text-justify;
        @extend .px-1;
        font-size: 0.7rem;
        @extend .mb-0;
    }
    .avatar{      
        position: relative;
        
        img{
            @extend .rounded-circle;
            width: 45px;
            height: 45px;
            border: 1px solid #dcdcdc;
        }
    }
    .user-status{
        position: absolute;
        width: 10px;
        height: 10px;
        left: -2px;
        top: -2px;  
        @extend .rounded-circle; 
    }
    .offline{            
        background-color: #cf000f;            
    }
    .online{
        background-color: #18af85;            
    }
}
.user-item.active{
    border: 1px solid green;
}
.user-id{
    @extend .text-secondary;
    @extend .font-weight-bold;
}

.user-search{  
    @extend .d-flex;
    // @extend .mb-1;
}
.btn-search{
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;
    @extend .rounded-0;
//     -webkit-border-radius: 0px;
// -moz-border-radius: 0px;
// border-radius: 0px;

}
// end users-container
