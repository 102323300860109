@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wsqil');
  src:  url('fonts/icomoon.eot?wsqil#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wsqil') format('truetype'),
    url('fonts/icomoon.woff?wsqil') format('woff'),
    url('fonts/icomoon.svg?wsqil#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tick-3:before {
  content: "\e90c";
  color: #6d6e71;
}
.icon-caret-down:before {
  content: "\e90f";
}
.icon-info1:before {
  content: "\e910";
}
.icon-tick:before {
  content: "\e911";
}
.icon-tick-2:before {
  content: "\e913";
}
.icon-clip:before {
  content: "\e914";
}
.icon-down-arrow:before {
  content: "\e915";
}
.icon-google-plus-logo-button:before {
  content: "\e904";
}
.icon-twitter-logo-button:before {
  content: "\e905";
}
.icon-facebook-logo-button:before {
  content: "\e906";
}
.icon-facebook-placeholder-for-locate-places-on-maps:before {
  content: "\e907";
}
.icon-instagram1:before {
  content: "\e908";
}
.icon-preference:before {
  content: "\e909";
}
.icon-empty:before {
  content: "\e90a";
}
.icon-maps-and-flags:before {
  content: "\e90b";
}
.icon-old-typical-phone:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-play:before {
  content: "\e912";
}
.icon-calculator:before {
  content: "\e940";
}
.icon-tablet:before {
  content: "\e95a";
}
.icon-database:before {
  content: "\e964";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-bubbles2:before {
  content: "\e96d";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-user-check:before {
  content: "\e975";
}
.icon-spinner:before {
  content: "\e97a";
}
.icon-spinner2:before {
  content: "\e97b";
}
.icon-spinner3:before {
  content: "\e97c";
}
.icon-spinner4:before {
  content: "\e97d";
}
.icon-spinner5:before {
  content: "\e97e";
}
.icon-spinner6:before {
  content: "\e97f";
}
.icon-spinner7:before {
  content: "\e980";
}
.icon-spinner8:before {
  content: "\e981";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner10:before {
  content: "\e983";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-upload2:before {
  content: "\e9c6";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-heart-broken:before {
  content: "\e9db";
}
.icon-point-up:before {
  content: "\ea03";
}
.icon-point-right:before {
  content: "\ea04";
}
.icon-point-down:before {
  content: "\ea05";
}
.icon-point-left:before {
  content: "\ea06";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-play2:before {
  content: "\ea15";
}
.icon-pause:before {
  content: "\ea16";
}
.icon-previous:before {
  content: "\ea18";
}
.icon-next:before {
  content: "\ea19";
}
.icon-backward:before {
  content: "\ea1a";
}
.icon-forward2:before {
  content: "\ea1b";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-pause2:before {
  content: "\ea1d";
}
.icon-stop2:before {
  content: "\ea1e";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-paragraph-left:before {
  content: "\ea77";
}
.icon-paragraph-center:before {
  content: "\ea78";
}
.icon-paragraph-right:before {
  content: "\ea79";
}
.icon-paragraph-justify:before {
  content: "\ea7a";
}
.icon-indent-increase:before {
  content: "\ea7b";
}
.icon-indent-decrease:before {
  content: "\ea7c";
}
.icon-embed:before {
  content: "\ea7f";
}
.icon-embed2:before {
  content: "\ea80";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail:before {
  content: "\ea83";
}
.icon-mail2:before {
  content: "\ea84";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-telegram:before {
  content: "\ea95";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-rss:before {
  content: "\ea9b";
}
.icon-flickr:before {
  content: "\eaa3";
}
.icon-flickr2:before {
  content: "\eaa4";
}
.icon-flickr3:before {
  content: "\eaa5";
}
.icon-onedrive:before {
  content: "\eaaf";
}
.icon-github:before {
  content: "\eab0";
}
.icon-tumblr2:before {
  content: "\eaba";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows:before {
  content: "\eac1";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-soundcloud:before {
  content: "\eac3";
}
.icon-soundcloud2:before {
  content: "\eac4";
}
.icon-pinterest:before {
  content: "\ead1";
}
.icon-pinterest2:before {
  content: "\ead2";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-html-five2:before {
  content: "\eae5";
}
.icon-css3:before {
  content: "\eae6";
}
.icon-git:before {
  content: "\eae7";
}
.icon-codepen:before {
  content: "\eae8";
}
.icon-svg:before {
  content: "\eae9";
}
