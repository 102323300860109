.chat-info {
    width: 270px;
    height: 100%;
    header {
        @extend .bg-primary;
        @extend .text-white;
        @extend .py-2;
    }
    .info {
        @extend .d-flex;
        @extend .shadow-sm;
        @extend .mb-2;
    }
    .data {
        @extend .text-center;
        @extend .p-2;
        @extend .d-flex;
        @extend .flex-column;
        word-break: break-word;

        > div {
            @extend .d-flex;
        }
        .label {
            @extend .d-inline-block;
            @extend .ml-1;
            font-size: 0.9rem;
            @extend .font-weight-bold;
        }
    }

    .avatar {
        position: relative;
        @extend .p-1;
        @extend .pt-2;
        img {
            height: 70px;
            width: 70px;
            @extend .rounded-circle;
        }
    }
    .user-status {
        position: absolute;
        width: 15px;
        height: 15px;
        right: 0.1rem;
        top: 0.1rem;
        @extend .rounded-circle;
    }
    .offline {
        background-color: #cf000f;
    }
    .online {
        background-color: #18af85;
    }
}

.user-labels {
    height: auto;
    min-height: 40px;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .shadow-sm;
    // @extend .p-1;
    > div {
        border: 1px solid #dbdbdb;
        @extend .px-1;
        @extend .mb-1;
        @extend .ml-1;
        @extend .rounded;
    }

    .label {
        @extend .d-inline-block;
        @extend .px-2;
        @extend .font-weight-bold;
    }
    .lable-delete {
        // color: #dc3545;
        color: #dc3545;
        cursor: pointer;
        @extend .d-inline-block;
        @extend .mr-1;
        font-size: 0.7rem;
        vertical-align: baseline;
    }
}
.label-form {
    // @extend .p-1;
    @extend .d-flex;
    // @extend .text
    @extend .text-right;
    @extend .shadow-sm;

    input:active,
    input:hover,
    input:enabled,
    input:focus {
        // text-align: center;
        border: 1px solid #cccccc;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    button {
        @extend .rounded-0;
    }
}

.aside-disease-category {
    @extend .list-group;
    li {
        cursor: pointer;
    }
}

.user-cycles {
    .swal2-header {
        font-size: 1.2rem !important;
        // border: 1px solid red;
    }
}
.swal2-title {
    font-size: 1.4rem !important; 
    @extend .py-2;
    @extend .mb-0;
}

.question-answers {
    .swal2-header {
        font-size: 1.2rem !important;
        border: 1px solid #cccccc;
        @extend .shadow-sm;
        @extend .rounded;
    }
    .swal2-title {
        color: #333333;
        @extend .font-weight-bold;
    }

    .swal2-content {
        @extend .py-2;
        @extend .text-right;
    }
}
