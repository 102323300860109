.not-found{    
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}