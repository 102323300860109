.navigation {
    z-index: 3;
    width: 78px;
    height: 100%;
    background-color: #1c293b;
    -webkit-app-region: drag;

    ul {
        li {
            @extend .px-1;
            @extend .mb-1;
        }
    }
}

.disconnect {
    @extend .btn;
    @extend .btn-danger;
    @extend .py-1;
    @extend .btn-sm;
    @extend .btn-block;
}
.connect {
    @extend .btn;
    @extend .btn-primary;
    @extend .py-1;
    @extend .btn-sm;
    @extend .btn-block;
}
