.loading-wrapper{
    border: 1px solid #dbdbdb;
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    right: 0;
    background-color:rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}