html,
body {
    height: 100%;
}
body {
    direction: rtl;
    text-align: right;
    font-size: 0.87rem;
}

ol,
ul {
    list-style: none;
    @extend .p-0;
}
a:hover,
a:active,
a:focus,
a {
    text-decoration: none;
    color: #333333;
}
.date {
    @extend .d-inline-block;
    direction: ltr;
}
